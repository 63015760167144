import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {AuthenticationService} from '../../school/service/authentication.service';
import { ErrorHandlingService } from '../../service/error-handling.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { CookieService } from '../service/cookie.service';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  refresh=false
  constructor(
    private authService: AuthenticationService,
    public http: HttpClient,
    private router: Router,
    public errorHandlingService: ErrorHandlingService,
    public cookie: CookieService,
    @Inject(DOCUMENT) private document: Document
    ) {}

    url: any = environment.apiUrl;
    public apiUrl;
    public redirect;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

    let urlPath = this.document.location.pathname;
    let urlSplit = urlPath.split('/');
     if(urlSplit.includes('school')){
      this.apiUrl = this.url + '/school/';
      this.redirect = this.url + '/school/pupils-progress';
     }
     if(urlSplit.includes('teacher')){
      this.apiUrl = this.url + '/auth/';
      this.redirect = this.url + '/teacher/home';
     }   

   let email = localStorage.getItem('email');
   let token = localStorage.getItem('token');
   let refreshtoken = localStorage.getItem('refreshtoken');

        if (token) {
          request = request.clone({
                setHeaders: {
                    'x-auth-token': token
                }
            } );
        }else{
             request.clone({
                  setHeaders: {
                      'x-auth-token':refreshtoken
                  }
              } );
        }
        return next.handle(request).pipe(
          catchError((error: any) =>{
            if(error.status==400  && !urlSplit.includes('school-login')  && !urlSplit.includes('teacher-login') && !urlSplit.includes('') && !urlSplit.includes('register')){
            
            return this.http
            .post<any>(`${this.apiUrl}/refreshtoken`, {
             email,
             refreshtoken
            }).pipe(
              map((da) => {
                localStorage.setItem('email', email);
                localStorage.setItem('token',da.data.token);
                localStorage.setItem('refreshtoken',da.data.refreshToken);
                this.router.navigateByUrl(this.redirect);
                return da;
              })
            );
   
            }
            return throwError(error);
          })
        );


  }
}


