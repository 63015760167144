import { Injectable } from '@angular/core';

interface timestamp {
  startTime: Date;
  endTime: Date;
}

export const timestamp_localstoragename = 'timestamparray';
@Injectable({
  providedIn: 'root',
})
export class TimestampService {
  timestampArray: Array<timestamp> = [];
  constructor() {}

  addTimeStamps(t: timestamp) {
    let TSA: any = localStorage.getItem(timestamp_localstoragename);
    if (TSA) {
      TSA = <timestamp>JSON.parse(TSA);
      TSA.push(t);
      localStorage.setItem(timestamp_localstoragename, JSON.stringify(TSA));
    } else {
      this.timestampArray.push(t);
      localStorage.setItem(
        timestamp_localstoragename,
        JSON.stringify(this.timestampArray)
      );
    }
  }

  clearLocalStorage() {
    localStorage.removeItem(timestamp_localstoragename);
    this.timestampArray = [];
  }

  sendTimeStamps(cb) {
    // API hit kro aur subscribe me callback run kro
    cb();
  }

  getTimestampArray(): Array<timestamp> {
    const TSA: Array<timestamp> = JSON.parse(
      localStorage.getItem(timestamp_localstoragename)
    );
    return TSA;
  }
}
