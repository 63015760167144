import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError,map, tap } from 'rxjs/operators';
import { School } from '../model/school';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from '../../service/error-handling.service';
import { Router } from '@angular/router';
import { CookieService } from '../service/cookie.service';

import {
  TimestampService,
  timestamp_localstoragename,
} from 'src/app/Common/Services/timestamp.service';

export interface LoginForm {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  url: any = environment.apiUrl;
  private apiUrl = this.url + '/school';
  redirectUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    public errorHandlingService: ErrorHandlingService,
    private timeStampService: TimestampService,
    public cookie: CookieService
  ) {}

  isSchoolSubmit(){
    localStorage.setItem("isSchoolActivated",new Boolean(true).toString());
  }

  getSchoolSubmit(){
   return localStorage.getItem('isSchoolActivated');
  }
  

  getToken() {
    return JSON.parse(localStorage.getItem('schoolUser.token'));
  }

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + this.getToken(),
  });

  signup(data: School) {
    return this.http.post(`${this.apiUrl}/addSchool`, data).pipe(
      tap((data) => console.log(data)),
      map((data) => data)
    );
  }

  check_server(){
    return this.http.get(
        `${environment.apiUrl}/auth/health`,
        {'headers':{ 'content-type': 'application/json'}  }
      );
  }

  refresh(email:any,refreshtoken:any) {
    return this.http
      .post<any>(`${this.apiUrl}/refreshtoken`, {
       email,
       refreshtoken
      })
      .pipe(
        catchError((error) =>
          this.errorHandlingService.handleError(error, error.error)
        )
      );
  }

  login(loginForm: LoginForm) {
    return this.http
      .post<any>(`${this.apiUrl}/login`, {
        email: loginForm.email,
        password: loginForm.password,
      })
      .pipe(
        map((data) => {

          let token = data?.data.token;
          let refreshtoken = data?.data.refreshToken;
          let email = data?.data.userInfo.email;

          localStorage.setItem('email', email);
          localStorage.setItem('token', token);
          localStorage.setItem('refreshtoken', refreshtoken);

          this.cookie.setCookie({
            name: 'email',
            value: email,
            session: true,
          });
          this.cookie.setCookie({
            name: 'token',
            value: token,
            session: true,
          });
          this.cookie.setCookie({
            name: 'refreshtoken',
            value: refreshtoken,
            session: true,
          });
          
          localStorage.setItem('schoolUser', JSON.stringify(data));
          return data;
        })
      ).pipe(
        catchError((error) =>
          this.errorHandlingService.handleError(error, error.error)
        )
      );
  }
  
  isAuthenticated(): boolean {
    return JSON.parse(localStorage.getItem('schoolUser'));
  }

  async logout() {
    await this.handleLoginTimestamps();
     localStorage.clear();
    localStorage.removeItem('schoolId');
    localStorage.removeItem('schoolUser');
    localStorage.removeItem('isSchoolActivated');
    this.timeStampService.clearLocalStorage();
    //this.router.navigate(['/school/school-login']);
    this.router.navigate(['/']);
    //location.reload();
  }

  handleLoginTimestamps = async () => {
    const schoolid: string = JSON.parse(localStorage.getItem('schoolUser')).data?.userInfo._id;

    const timestamps: { startTime: Date; endTime: Date }[] = JSON.parse(
      localStorage.getItem(timestamp_localstoragename)
    );

    return this.http
      .put(
        `${this.url}/users/loginlog/1`,
        {
          id: schoolid,
          log: timestamps,
          device: 'web',
        },
        {
          headers: this.headers,
        }
      ).pipe(
        catchError((error) =>
          this.errorHandlingService.handleError(error, error.error)
        )
      ).toPromise();
  };

  check_finish_book(){

    return this.http.get(
        `${environment.apiUrl}/ebook/completeBook`,
        {'headers':{ 'content-type': 'application/json'}  }
      );
  }


}
