import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {AuthenticationService} from '../../teacher/services/authentication.service';
import { ErrorHandlingService } from '../../service/error-handling.service';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthTeacherInterceptor implements HttpInterceptor {
  refresh=false

  constructor(
    private authService: AuthenticationService,
    public http: HttpClient,
    private router: Router,
    public errorHandlingService: ErrorHandlingService,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>{
    
      let urlPath = this.document.location.pathname;
      let urlSplit = urlPath.split('/');

      const schoolUser = JSON.parse(localStorage.getItem('schoolUser'));
      if(urlSplit.includes('school') && schoolUser?.data==null){
          //this.router.navigate(['/school/school-login']); 
          this.router.navigate(['/']); 
      }
      const teacherUser = JSON.parse(localStorage.getItem('teacher'));
      if(urlSplit.includes('teacher') && teacherUser?.data==null){
          // this.router.navigate(['/teacher/teacher-login']); 
           this.router.navigate(['/']); 
       }   

       return next.handle(request);
    
  }
}
