import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError,map, tap } from 'rxjs/operators';
import {
  TimestampService,
  timestamp_localstoragename,
} from 'src/app/Common/Services/timestamp.service';
import { ErrorHandlingService } from '../../service/error-handling.service';
import { environment } from 'src/environments/environment';

export interface LoginForm {
  email: string;
  password: string;
}


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    public errorHandlingService: ErrorHandlingService,
    private timeStampService: TimestampService
  ) {
    this.apiUrl = environment.apiUrl;
  }

  getToken = () => {
    let data = JSON.parse(localStorage.getItem('teacher'));
    if (!data) {
      data = JSON.parse(localStorage.getItem('schoolUser'));
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth-token': data.data.token,
    });

    return headers;
  };

  isAuthenticated = () => {
    const teacher = localStorage.getItem('teacher');
    return JSON.parse(teacher);
  };

  


  login(loginForm: LoginForm) {
    return this.http
      .post<any>(`${this.apiUrl}/auth/teacher`, {
        email: loginForm.email,
        password: loginForm.password,
      })
      .pipe(
        map((data) => {
          let token = data?.data.token;
          let refreshtoken = data?.data.refreshToken;
          let email = data?.data.teacherInfo.teacher_email;
          localStorage.setItem('email', email);
          localStorage.setItem('token', token);
          localStorage.setItem('refreshtoken', refreshtoken);
          localStorage.setItem('schoolUser', JSON.stringify(data));
          return data;
        })
      ).pipe(
        catchError((error) =>
          this.errorHandlingService.handleError(error, error.error)
        )
      );
      
  }

  async logout() {
    await this.handleLoginTimestamps();
    localStorage.removeItem('teacher');
    this.timeStampService.clearLocalStorage();
    localStorage.clear();
    //this.router.navigate(['/teacher/teacher-login']);
    this.router.navigate(['/']);
    //location.reload();
  }

  handleLoginTimestamps = async () => {
    
    const teacherId: string = JSON.parse(localStorage.getItem('teacher')).data
      .teacherInfo._id;

    const timestamps: { startTime: Date; endTime: Date }[] = JSON.parse(
      localStorage.getItem(timestamp_localstoragename)
    );
    return this.http
      .put(
        `${this.apiUrl}/users/loginlog/2`,
        {
          id: teacherId,
          log: timestamps,
          device: 'web',
        },
        {
          headers: this.getToken(),
        }
      ).pipe(
        catchError((error) =>
          this.errorHandlingService.handleError(error, error.error)
        )
      ).toPromise();
  };

  forgot_email_school(email){
    return this.http.post(
        `${this.apiUrl}/school/forgot-link`,
        {email},
        {'headers':{ 'content-type': 'application/json'}  }
      );
  }

  forgot_email_teacher(email){
    return this.http.post(
        `${this.apiUrl}/teachermaster/forgot-link`,
        {email},
        {'headers':{ 'content-type': 'application/json'}  }
      );
  }

  check_finish_session(){
    return this.http.get(
        `${this.apiUrl}/ebook/completeSession`,
        {'headers':{ 'content-type': 'application/json'}  }
      );
  }

}
