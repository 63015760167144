// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   apiUrl: 'https://admin.readingmate.co.uk/api',
   apiUrlTour:'https://readinghub.readingmate.co.uk/',
  // apiUrl: 'http://3.10.107.152/api',
 //  apiUrlTour:'http://3.10.107.152/api/assets/shops/',
   //apiUrl: 'http://localhost:4000/api',
  // apiUrlTour: 'http://localhost:4000',
  // sessionIdleTime: 60 * 60 * 60,
  // sessionTimeOutTime: 60 * 60 * 60,
  // sessionIdleTime: 5,
  // sessionTimeOutTime: 5,
  sessionIdleTime: 5 * 60,
  sessionTimeOutTime: 5 * 60,
  base: 'https://admin.readingmate.co.uk',
  amplitudeKey:"e3afcf46c91003a40bb5418a9d179337"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
