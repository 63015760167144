import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable,throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private toastr: ToastrService,    
    public router: Router
  ) { }

  // Handle HTTP Errors
  public handleError(errorRes: Response, errorMsg?) {
    if (errorRes.status === 400) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'400 Bad Request', 'Server Error');
    } else if (errorRes.status === 401) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'401 Unauthorized', 'Server Error');
      localStorage.removeItem('appUser');
      localStorage.removeItem('appAdmin');
      this.router.navigate(['/']);
    } else if (errorRes.status === 403) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'403 Forbidden', 'Server Error');
    } else if (errorRes.status === 404) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'404 Not Found', 'Server Error');
    } else if (errorRes.status === 405) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'405 Wrong Request', 'Server Error');
    } else if (errorRes.status === 500 || errorRes.status === 0) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'500 Server Error', 'Server Error');
    } else if (errorRes.status === 502) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'502 Bad Gateway', 'Server Error');
    } else if (errorRes.status === 504) {
      this.toastr.error((errorMsg.message)?errorMsg.message:'504 Network Error', 'Server Error');
    }    return throwError(errorRes);
  }

}
