import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./frontend/frontend.module').then((m) => m.FrontendModule),
  },
  {
    path: 'school',
    data: { breadcrumb: 'School', url: '/school/pupils-progress' },
    loadChildren: () =>
      import('./school/school.module').then((m) => m.SchoolModule),
  },
  {
    path: 'teacher',
    data: { breadcrumb: 'Teacher', url: '/teacher/dashboard' },
    loadChildren: () =>
      import('./teacher/teacher.module').then((m) => m.TeacherModule),
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
