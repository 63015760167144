import { NgModule } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import{AuthInterceptorInterceptor} from '../app/school/helper/auth-interceptor.interceptor'
import{AuthTeacherInterceptor} from '../app/teacher/helper/auth-interceptor.interceptor'


import { AppComponent } from './app.component';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),

  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorInterceptor, multi:true,
    },
    { 
      provide: HTTP_INTERCEPTORS, useClass: AuthTeacherInterceptor, multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
