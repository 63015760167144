import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/school/service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'reding-mate';
  location: Location;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    // setInterval(() => {
    //   this.authenticationService.check_server().subscribe(
    //     (response) => { },
    //     (error) => {
    //       this.toastr.info('Oops! It seems like the server is unreachable. Please try again later.');
    //       this.router.navigate(['/']);
    //     }
    //   );
    // }, 6000);
  }

}
